import { useEffect, useState } from 'react';

export default function useCountdown(numSeconds) {
  const [secondsRemaining, setSecondsRemaining] = useState(numSeconds);

  useEffect(() => {
    setTimeout(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      }
    }, 1000);
  }, [secondsRemaining]);

  return secondsRemaining;
}
