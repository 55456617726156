export default function validate(newUserObject, oldEmail) {
  const fieldErrorsObj = {};
  const fieldsHelperTextObj = {};

  // Takes the newUser state object as an argument which is just a user object with all the fields and associated values

  // loop through the properties and for each one, run the respective validation function, if it fails validation, return the appropriate error message for its field name in the fieldErrors object
  Object.keys(newUserObject).forEach((key) => {
    const validation = handleValidation(key, newUserObject[key]);

    if (!validation) {
      // if validation = false, just make sure the error for that field in the field errors object is set to false
      return;
    }
    // otherwise set the fieldErrors to true for that field, and set the helperText for that field equal to the value returned from the validation function
    fieldErrorsObj[key] = true;
    fieldsHelperTextObj[key] = validation;
  });

  if (newUserObject.newEmail !== newUserObject.confirmNewEmail) {
    fieldErrorsObj.confirmNewEmail = true;
    fieldsHelperTextObj.confirmNewEmail = 'Emails do not match.';
  }

  if (String(newUserObject.newEmail).toUpperCase() === String(oldEmail).toUpperCase()) {
    fieldErrorsObj.newEmail = true;
    fieldsHelperTextObj.newEmail = 'Email cannot be your current email.';
  }

  return [fieldErrorsObj, fieldsHelperTextObj];

  /** **************************************** FIELD VALIDATION FUNCTIONS ****************************************************************** */

  function handleValidation(fieldName, value) {
    switch (fieldName) {
      case 'newEmail':
        return newEmail(value);
      case 'confirmNewEmail':
        return confirmNewEmail(value);
      default:
        return false;
    }
  }

  function newEmail(value) {
    if (!value) {
      return 'New email is required.';
    }
    return false;
  }

  function confirmNewEmail(value) {
    if (!value) {
      return 'Please confirm your new email.';
    }
    return false;
  }
}
