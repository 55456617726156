import { Auth } from '@aws-amplify/auth';
import { RestAPI } from '@aws-amplify/api-rest';

import { getSuccessRedirect } from 'util/cookieStorage';

const EXTERNAL_LINK_GENERATOR_PATH = 'external-link';

export const LinkType = Object.freeze({
  Hr: 'hr',
  Cybernet: 'cybernet',
  MedIq: 'med-iq',
});

export async function redirectToLink(params) {
  // params are URLSearchParams
  const type = params.get('type');
  if (!Object.values(LinkType).includes(type)) {
    console.err(`Invalid link type provided: ${type}`);
    return false;
  }

  const link = await generateLink(params);
  if (!link) {
    return false;
  }

  switch (type) {
    case LinkType.Hr:
    case LinkType.MedIq:
      return simpleRedirect(link);
    case LinkType.Cybernet:
      return launchFormRedirect(link);
    default:
      // should not occur due to check above
      return false;
  }
}

async function generateLink(params) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const { link } = await RestAPI.get(
      EXTERNAL_LINK_GENERATOR_PATH,
      `/${EXTERNAL_LINK_GENERATOR_PATH}?${params.toString()}`,
      {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
    return link;
  } catch (err) {
    console.error('Failed to generate link', err);
    return false;
  }
}

async function simpleRedirect(link) {
  window.location.href = link;
  return true;
}

async function launchFormRedirect(link) {
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.action = link;
  form.method = 'POST';
  form.submit();
  return true;
}

export function redirectToSuccess() {
  const successUrl = getSuccessRedirect();
  window.location.replace(successUrl);
}

export async function updatePhsUserEmail() {
  try {
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return await RestAPI.post('phs-email', '/resource-server/phs-email', {
      headers: {
        Authorization: accessToken,
      },
    });
  } catch (err) {
    console.error('Failed to update phs user email response', err);
    return false;
  }
}
