import { CookieStorage } from '@aws-amplify/auth';
import awsConfig from 'aws-config';

const AUTH_REDIRECT_KEY = 'profile-auth-redirect';
const SUCCESS_REDIRECT_KEY = 'profile-success-redirect';

const storage = new CookieStorage(awsConfig.Auth.cookieStorage);

export function getAuthRedirect() {
  return storage.getItem(AUTH_REDIRECT_KEY);
}

export function setAuthRedirect(value) {
  return storage.setItem(AUTH_REDIRECT_KEY, value);
}

export function clearAuthRedirect() {
  return storage.removeItem(AUTH_REDIRECT_KEY);
}

export function getSuccessRedirect() {
  return storage.getItem(SUCCESS_REDIRECT_KEY);
}

export function setSuccessRedirect(value) {
  return storage.setItem(SUCCESS_REDIRECT_KEY, value);
}

export function clearSuccessRedirect() {
  return storage.removeItem(SUCCESS_REDIRECT_KEY);
}
