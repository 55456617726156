/* eslint-disable react/jsx-filename-extension */
import 'index.css';

import { BrowserRouter } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import awsConfig from 'aws-config';

Amplify.configure(awsConfig);

// disable log in non-dev
if (process.env.NODE_ENV !== 'development' && !window.localStorage.getItem('debug')) {
  console.log = () => undefined;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
