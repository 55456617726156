import { Auth } from '@aws-amplify/auth';
import { CssBaseline } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import React, { useEffect, useMemo, useState } from 'react';

import { getAuthRedirect, setAuthRedirect, setSuccessRedirect } from 'util/cookieStorage';
import BrandedCardContainer from 'components/layout/BrandedCardContainer';
import Callback from 'pages/Callback';
import ChangeEmail from 'pages/ChangeEmail/ChangeEmail';
import ChangeEmailSuccess from 'pages/ChangeEmail/ChangeEmailSuccess';
import ChangeEmailVerification from 'pages/ChangeEmail/ChangeEmailVerification';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import ChangePasswordSuccess from 'pages/ChangePassword/ChangePasswordSuccess';
import Spinner from 'components/Spinner';
import UserContext from 'contexts/UserContext';
import theme from 'theme.js';

const CURATOR_URL = `${WEBPACK_CURI_WEBSITE_URL}curator`;
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const initialSearchParams = new URLSearchParams(window.location.search);
if (initialSearchParams.has('onSuccess')) {
  setSuccessRedirect(initialSearchParams.get('onSuccess'));
} else {
  setSuccessRedirect(CURATOR_URL);
}

// prevent redirecting back from auth to unexpected routes
const REDIRECTABLE_ROUTES = ['/change-password', '/change-email', '/logout'];
if (IS_DEVELOPMENT) {
  REDIRECTABLE_ROUTES.push('/');
}

// force a default
if (REDIRECTABLE_ROUTES.includes(window.location.pathname)) {
  setAuthRedirect(window.location.pathname);
} else if (window.location.pathname !== '/callback' || !getAuthRedirect()) {
  setAuthRedirect('/curator');
}

export default function App() {
  const location = useLocation();
  const [user, setUser] = useState({
    isAuthenticated: false,
    username: '',
    newEmail: '',
  });
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    async function execute() {
      if (REDIRECTABLE_ROUTES.includes(location.pathname)) {
        setAuthRedirect(location.pathname);
      }

      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log('already authenticated', user);
        setUser((prevUser) => ({
          ...prevUser,
          ...currentUser,
          isAuthenticated: true,
        }));
        console.log('currentUser', currentUser);
      } catch (err) {
        console.log('authentication error', err);
        // TODO consider tracking to avoid infinite loop in case of login error
        console.log('redirecting to login');
        Auth.federatedSignIn();
      }
    }

    execute();
  }, [location.pathname]);

  return (
    <UserContext.Provider value={userValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <BrandedCardContainer maxWidth="xs">
            {/* dev-only route to prevent immediate redirect to curator */}
            {IS_DEVELOPMENT && <Route exact path="/" component={Spinner} />}
            <Route
              exact
              path="/logout"
              component={() => {
                Auth.signOut({ global: true });
                return <Spinner />;
              }}
            />
            <Route
              exact
              path="/curator"
              component={() => {
                window.location.replace(CURATOR_URL);
                return <Spinner />;
              }}
            />
            {/* loading is same as callback, just a nicer path */}
            <Route exact path="/loading" component={Callback} />
            <Route exact path="/callback" component={Callback} />
            {user.isAuthenticated && (
              <>
                <Route exact path="/change-password" component={ChangePassword} />
                <Route exact path="/change-password-success" component={ChangePasswordSuccess} />
                <Route exact path="/change-email" component={ChangeEmail} />
                <Route exact path="/change-email-verification" component={ChangeEmailVerification} />
                <Route exact path="/change-email-success" component={ChangeEmailSuccess} />
              </>
            )}
            <Redirect from="*" to="/loading" />
          </BrandedCardContainer>
        </Switch>
      </ThemeProvider>
    </UserContext.Provider>
  );
}
