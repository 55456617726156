import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import logo from 'assets/full_curi_logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: theme.palette.colors.midnight,
    padding: theme.spacing(7.5, 4, 4),
  },
  paper: {
    padding: theme.spacing(4),
  },
  logoContainer: {
    textAlign: 'center',
    maxWidth: '100%',
    marginBottom: theme.spacing(4),
  },
  logo: {
    maxHeight: '30%',
    maxWidth: '60%',
  },
}));

export default function BrandedCardContainer({ maxWidth, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth={maxWidth}>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          {children}
        </Paper>
      </Container>
    </div>
  );
}

BrandedCardContainer.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
};

BrandedCardContainer.defaultProps = {
  children: null,
  maxWidth: 'lg',
};
