import { Auth } from '@aws-amplify/auth';
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import formStyles from 'components/layout/formStyles';

import validate from './validate';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  passwordRestriction: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const [fieldHelperText, setFieldHelperText] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [newUser, setNewUser] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdate = async (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const [fieldErrorsObj, fieldsHelperTextObj] = validate(newUser);

    if (Object.entries(fieldErrorsObj).length > 0) {
      setFieldErrors(fieldErrorsObj);
      setFieldHelperText(fieldsHelperTextObj);
      return;
    }
    console.log('there were no errors');
    setFieldHelperText(false);
    setFieldErrors(false);

    try {
      setIsSubmitting(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, newUser.oldPassword, newUser.newPassword);
      history.push('/change-password-success');
    } catch (authError) {
      console.log(authError);
      setError(authError.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main>
      <Typography gutterBottom component="h1" variant="body1">
        Change your password
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={Boolean(fieldErrors.oldPassword)}
              helperText={fieldHelperText.oldPassword ? fieldHelperText.oldPassword : ''}
              variant={fieldErrors.oldPassword ? 'filled' : 'outlined'}
              autoComplete="current-password"
              name="oldPassword"
              required
              fullWidth
              id="oldPassword"
              type="password"
              label="Old Password"
              data-test="change-password-old-password-input"
              autoFocus
              value={newUser.oldPassword}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={Boolean(fieldErrors.newPassword)}
              helperText={fieldHelperText.newPassword ? fieldHelperText.newPassword : ''}
              variant={fieldErrors.newPassword ? 'filled' : 'outlined'}
              autoComplete="new-password"
              type="password"
              required
              fullWidth
              id="newPassword"
              label="New Password"
              data-test="change-password-new-password-input"
              name="newPassword"
              value={newUser.newPassword}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={Boolean(fieldErrors.confirmNewPassword)}
              helperText={fieldHelperText.confirmNewPassword ? fieldHelperText.confirmNewPassword : ''}
              variant={fieldErrors.confirmNewPassword ? 'filled' : 'outlined'}
              autoComplete="new-password"
              required
              fullWidth
              id="confirmNewPassword"
              type="password"
              label="Confirm New Password"
              data-test="change-password-confirm-new-password-input"
              name="confirmNewPassword"
              value={newUser.confirmNewPassword}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.passwordRestriction}>Password must contain a lower case letter</Typography>
            <Typography className={classes.passwordRestriction}>Password must contain an upper case letter</Typography>
            <Typography className={classes.passwordRestriction}>Password must contain a number</Typography>
            <Typography className={classes.passwordRestriction}>
              Password must contain at least 12 characters
            </Typography>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              data-test="change-password-submit-button"
              onClick={handleSubmit}
              size="large"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={18} /> : undefined}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </main>
  );
}
