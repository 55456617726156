import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { redirectToSuccess } from 'util/common';
import Spinner from 'components/Spinner';
import useCountdown from 'hooks/useCountdown';

const REDIRECT_TIMEOUT = 5; // in seconds

export default function SuccessRedirector({ description }) {
  const secondsRemaining = useCountdown(REDIRECT_TIMEOUT);
  const [redirectMessage, setRedirectMessage] = useState('');
  const [forcedRedirect, setForcedRedirect] = useState(false);

  useEffect(() => {
    if (secondsRemaining === 0) {
      setRedirectMessage('Redirecting you now');
    } else if (secondsRemaining === 1) {
      setRedirectMessage('Redirecting you in 1 second');
    } else {
      setRedirectMessage(`Redirecting you in ${secondsRemaining} seconds`);
    }

    if (forcedRedirect || secondsRemaining <= 0) {
      redirectToSuccess();
    }
  }, [secondsRemaining, forcedRedirect]);

  return (
    <main>
      <Typography gutterBottom component="p" variant="body1">
        {description}
      </Typography>
      <Typography gutterBottom component="p" variant="body1">
        {redirectMessage}&hellip;
      </Typography>
      <Button color="primary" variant="text" fullWidth onClick={() => setForcedRedirect(true)}>
        Redirect Now
      </Button>
      {(forcedRedirect || secondsRemaining === 0) && <Spinner />}
    </main>
  );
}

SuccessRedirector.propTypes = {
  description: PropTypes.string.isRequired,
};
