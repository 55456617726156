import { Auth } from '@aws-amplify/auth';
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import UserContext from 'contexts/UserContext';
import formStyles from 'components/layout/formStyles';

import validate from './validate';

const useStyles = makeStyles((theme) => ({
  ...formStyles(theme),
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ChangeEmail() {
  const { user, setUser } = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    newEmail: '',
    confirmNewEmail: '',
  });
  const [fieldHelperText, setFieldHelperText] = useState({
    newEmail: '',
    confirmNewEmail: '',
  });
  const [newUser, setNewUser] = useState({
    newEmail: '',
    confirmNewEmail: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdate = async (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const [fieldErrorsObj, fieldsHelperTextObj] = validate(newUser, user.attributes.email);

    if (Object.entries(fieldErrorsObj).length > 0) {
      setFieldErrors(fieldErrorsObj);
      setFieldHelperText(fieldsHelperTextObj);
      return;
    }
    console.log('there were no errors');
    setFieldHelperText(false);
    setFieldErrors(false);

    try {
      setIsSubmitting(true);
      const tempUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(tempUser, {
        email: newUser.newEmail,
        'custom:validatedEmail': newUser.newEmail,
      });
      setUser({ ...user, newEmail: newUser.newEmail });
      history.push('/change-email-verification');
    } catch (authError) {
      console.log(authError);
      setError(authError.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main>
      <Typography gutterBottom component="h1" variant="body1">
        Change your email
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={Boolean(fieldErrors.newEmail)}
              helperText={fieldHelperText.newEmail ? fieldHelperText.newEmail : ''}
              variant={fieldErrors.newEmail ? 'filled' : 'outlined'}
              autoFocus
              type="email"
              required
              fullWidth
              id="newEmail"
              label="New Email"
              data-test="change-email-new-email-input"
              name="newEmail"
              value={newUser.newEmail}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={Boolean(fieldErrors.confirmNewEmail)}
              helperText={fieldHelperText.confirmNewEmail ? fieldHelperText.confirmNewEmail : ''}
              variant={fieldErrors.confirmNewEmail ? 'filled' : 'outlined'}
              required
              fullWidth
              id="confirmNewEmail"
              type="email"
              label="Confirm New Email"
              data-test="change-email-confirm-new-email-input"
              name="confirmNewEmail"
              value={newUser.confirmNewEmail}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              data-test="change-email-submit-button"
              onClick={handleSubmit}
              size="large"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={18} /> : undefined}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </main>
  );
}
