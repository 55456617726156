import { createTheme } from '@material-ui/core/styles';

const colors = {
  sun: '#FFDBF01',
  whale: '#A8BDCE',
  iris: '#8869AE',
  midnight: '#2C4465',
  aqua: '#65BCCD',
  pear: '#7FAF5C',
};

export default createTheme({
  palette: {
    colors,
    primary: {
      main: colors.midnight,
    },
    secondary: {
      main: colors.whale,
    },
  },
  typography: {
    fontFamily: [
      '"Work Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h5: {
      marginBottom: '1.5rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1.5rem',
      },
    },
  },
});
