import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

export default function Spinner() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={2} m={2}>
      <CircularProgress />
    </Box>
  );
}
