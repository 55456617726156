import { useHistory } from 'react-router';
import React, { useContext, useEffect } from 'react';

import { getAuthRedirect } from 'util/cookieStorage';
import Spinner from 'components/Spinner';
import UserContext from 'contexts/UserContext';

export default function Callback() {
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    // wait for user to be authenticated at App level to create route before redirecting
    if (user.isAuthenticated) {
      const redirectPath = getAuthRedirect();
      console.log('callback redirecting to', redirectPath);
      if (redirectPath) {
        history.replace(redirectPath);
      }
    }
  }, [user.isAuthenticated, history]);

  return <Spinner />;
}
