export default {
  Auth: {
    mandatorySignIn: false,
    region: 'us-east-1',
    userPoolId: WEBPACK_COGNITO_USERPOOL_ID,
    userPoolWebClientId: WEBPACK_COGNITO_CLIENT_ID,
    cookieStorage: {
      domain: WEBPACK_COGNITO_COOKIE_STORAGE_DOMAIN,
      secure: WEBPACK_COGNITO_COOKIE_STORAGE_SECURE,
      path: '/',
      expires: 1,
    },
    oauth: {
      domain: WEBPACK_COGNITO_OAUTH_DOMAIN,
      scope: [
        'aws.cognito.signin.user.admin',
        'openid',
        `https://api.${WEBPACK_COGNITO_OAUTH_DOMAIN}/phs.users.update`,
      ],
      redirectSignIn: WEBPACK_COGNITO_REDIRECT_URL,
      redirectSignOut: WEBPACK_COGNITO_LOGOUT_URL,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    endpoints: [
      {
        name: 'external-link',
        endpoint: WEBPACK_API_SERVICES_ENDPOINT,
      },
      {
        name: 'phs-email',
        endpoint: `https://api.${WEBPACK_COGNITO_OAUTH_DOMAIN}`,
      },
    ],
  },
};
