import { Auth } from '@aws-amplify/auth';
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import React, { useContext, useState } from 'react';

import { updatePhsUserEmail } from 'util/common';
import UserContext from 'contexts/UserContext';
import formStyles from 'components/layout/formStyles';

const useStyles = makeStyles(formStyles);

export default function ChangeEmailVerification() {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const handleUpdate = (e) => {
    setVerificationCode(e.target.value);
  };

  const confirm = async () => {
    const tempUser = await Auth.currentAuthenticatedUser();

    const result = await Auth.verifyUserAttributeSubmit(tempUser, 'email', verificationCode);

    if (result === 'SUCCESS') {
      try {
        await updatePhsUserEmail();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setVerificationCodeError('');

    if (!verificationCode) {
      setVerificationCodeError('Verification code is required.');
      return;
    }

    // AWS Cognito integration here
    try {
      setIsSubmitting(true);
      await confirm();
      history.push('/change-email-success');
    } catch (err) {
      console.error('there was an error confirming the new email', err);
      setError(err.message || 'There was an error confirming your email, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendConfirmationCode = async () => {
    setError('');
    try {
      setIsResending(true);
      const tempUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(tempUser, {
        email: user.newEmail,
        'custom:validatedEmail': tempUser.attributes.email,
      });
    } catch (err) {
      console.log('error resending code', err);
      setError('There was an error sending your code, please try again');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <main>
      <Typography gutterBottom component="h1" variant="body1">
        Please check your new email address and enter the code you received.
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={Boolean(verificationCodeError)}
              helperText={verificationCodeError}
              variant={verificationCodeError ? 'filled' : 'outlined'}
              autoComplete="one-time-code"
              margin="normal"
              required
              fullWidth
              id="verificationCode"
              label="Verification Code"
              name="verificationCode"
              autoFocus
              data-test="verify-email-verification-code-input"
              type="text"
              value={verificationCode}
              onChange={handleUpdate}
              disabled={isSubmitting}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              data-test="verify-email-confirm-button"
              className={classes.submit}
              onClick={handleSubmit}
              size="large"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={18} /> : undefined}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="text"
              data-test="verify-email-resend-code"
              onClick={resendConfirmationCode}
              size="large"
              disabled={isResending}
              startIcon={isResending ? <CircularProgress size={18} /> : undefined}
            >
              Resend Code
            </Button>
          </Grid>
        </Grid>
      </form>
    </main>
  );
}
